import * as React from "react";
import { API, graphqlOPeration, Storage } from 'aws-amplify'
import { Text, View, AmplifyS3Image } from "@aws-amplify/ui-react";
import { NavBar, ProfileInfo, ProfileSelector, GridRow } from "../ui-components/index.js";
import { signOut } from 'aws-amplify/auth'

export default function Profile(props) {
  return (
    <>
      <NavBar
        className="navbar"
        width="100vw"
        displayMode="Profile"
      />
      <View
          width="100vw"
          height='55px'
      >

      </View>

      <button
        type="button"
        className="fixed"
        onClick={() => signOut()}
      >Sign Out</button>

      <ProfileInfo width="100vw" />
      <ProfileSelector width="100vw" />
      <GridRow width="100vw" />
      <GridRow width="100vw" />
      <GridRow width="100vw" />

    </>
  );
}


/*
        <div className="ProfilePic">
          <input 
            type="file" accept='image/png'
            onChange={(e) => uploadPhoto(e.target.file[0])}
          />
          <AmplifyS3Image level='private' imgKey={'profile.png'}/>
          
        </div>
*/