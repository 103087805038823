/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Autocomplete,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  ScrollView,
  Text,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getCar, getUser, listCars } from "../graphql/queries";
import { updateCar, updateUser } from "../graphql/mutations";
const client = generateClient();
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function UserUpdateForm(props) {
  const {
    id: idProp,
    user: userModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    id: "",
    username: "",
    email: "",
    profilePic: "",
    cars: [],
    carFollowersId: undefined,
  };
  const [id, setId] = React.useState(initialValues.id);
  const [username, setUsername] = React.useState(initialValues.username);
  const [email, setEmail] = React.useState(initialValues.email);
  const [profilePic, setProfilePic] = React.useState(initialValues.profilePic);
  const [cars, setCars] = React.useState(initialValues.cars);
  const [carsLoading, setCarsLoading] = React.useState(false);
  const [carsRecords, setCarsRecords] = React.useState([]);
  const [carFollowersId, setCarFollowersId] = React.useState(
    initialValues.carFollowersId
  );
  const [carFollowersIdLoading, setCarFollowersIdLoading] =
    React.useState(false);
  const [carFollowersIdRecords, setCarFollowersIdRecords] = React.useState([]);
  const [selectedCarFollowersIdRecords, setSelectedCarFollowersIdRecords] =
    React.useState([]);
  const autocompleteLength = 10;
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = userRecord
      ? { ...initialValues, ...userRecord, cars: linkedCars, carFollowersId }
      : initialValues;
    setId(cleanValues.id);
    setUsername(cleanValues.username);
    setEmail(cleanValues.email);
    setProfilePic(cleanValues.profilePic);
    setCars(cleanValues.cars ?? []);
    setCurrentCarsValue(undefined);
    setCurrentCarsDisplayValue("");
    setCarFollowersId(cleanValues.carFollowersId);
    setCurrentCarFollowersIdValue(undefined);
    setCurrentCarFollowersIdDisplayValue("");
    setErrors({});
  };
  const [userRecord, setUserRecord] = React.useState(userModelProp);
  const [linkedCars, setLinkedCars] = React.useState([]);
  const canUnlinkCars = true;
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getUser.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getUser
        : userModelProp;
      const linkedCars = record?.cars?.items ?? [];
      setLinkedCars(linkedCars);
      const carFollowersIdRecord = record ? record.carFollowersId : undefined;
      const carRecord = carFollowersIdRecord
        ? (
            await client.graphql({
              query: getCar.replaceAll("__typename", ""),
              variables: { id: carFollowersIdRecord },
            })
          )?.data?.getCar
        : undefined;
      setCarFollowersId(carFollowersIdRecord);
      setSelectedCarFollowersIdRecords([carRecord]);
      setUserRecord(record);
    };
    queryData();
  }, [idProp, userModelProp]);
  React.useEffect(resetStateValues, [userRecord, linkedCars, carFollowersId]);
  const [currentCarsDisplayValue, setCurrentCarsDisplayValue] =
    React.useState("");
  const [currentCarsValue, setCurrentCarsValue] = React.useState(undefined);
  const carsRef = React.createRef();
  const [
    currentCarFollowersIdDisplayValue,
    setCurrentCarFollowersIdDisplayValue,
  ] = React.useState("");
  const [currentCarFollowersIdValue, setCurrentCarFollowersIdValue] =
    React.useState(undefined);
  const carFollowersIdRef = React.createRef();
  const getIDValue = {
    cars: (r) => JSON.stringify({ id: r?.id }),
  };
  const carsIdSet = new Set(
    Array.isArray(cars)
      ? cars.map((r) => getIDValue.cars?.(r))
      : getIDValue.cars?.(cars)
  );
  const getDisplayValue = {
    cars: (r) => `${r?.year ? r?.year + " - " : ""}${r?.id}`,
    carFollowersId: (r) => `${r?.year ? r?.year + " - " : ""}${r?.id}`,
  };
  const validations = {
    id: [{ type: "Required" }],
    username: [],
    email: [],
    profilePic: [],
    cars: [],
    carFollowersId: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const fetchCarsRecords = async (value) => {
    setCarsLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ year: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listCars.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listCars?.items;
      var loaded = result.filter(
        (item) => !carsIdSet.has(getIDValue.cars?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setCarsRecords(newOptions.slice(0, autocompleteLength));
    setCarsLoading(false);
  };
  const fetchCarFollowersIdRecords = async (value) => {
    setCarFollowersIdLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ year: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listCars.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listCars?.items;
      var loaded = result.filter((item) => carFollowersId !== item.id);
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setCarFollowersIdRecords(newOptions.slice(0, autocompleteLength));
    setCarFollowersIdLoading(false);
  };
  React.useEffect(() => {
    fetchCarsRecords("");
    fetchCarFollowersIdRecords("");
  }, []);
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          id,
          username: username ?? null,
          email: email ?? null,
          profilePic: profilePic ?? null,
          cars: cars ?? null,
          carFollowersId: carFollowersId ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(
                    fieldName,
                    item,
                    getDisplayValue[fieldName]
                  )
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(
                fieldName,
                modelFields[fieldName],
                getDisplayValue[fieldName]
              )
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const promises = [];
          const carsToLink = [];
          const carsToUnLink = [];
          const carsSet = new Set();
          const linkedCarsSet = new Set();
          cars.forEach((r) => carsSet.add(getIDValue.cars?.(r)));
          linkedCars.forEach((r) => linkedCarsSet.add(getIDValue.cars?.(r)));
          linkedCars.forEach((r) => {
            if (!carsSet.has(getIDValue.cars?.(r))) {
              carsToUnLink.push(r);
            }
          });
          cars.forEach((r) => {
            if (!linkedCarsSet.has(getIDValue.cars?.(r))) {
              carsToLink.push(r);
            }
          });
          carsToUnLink.forEach((original) => {
            if (!canUnlinkCars) {
              throw Error(
                `Car ${original.id} cannot be unlinked from User because userCarsId is a required field.`
              );
            }
            promises.push(
              client.graphql({
                query: updateCar.replaceAll("__typename", ""),
                variables: {
                  input: {
                    id: original.id,
                    userCarsId: null,
                  },
                },
              })
            );
          });
          carsToLink.forEach((original) => {
            promises.push(
              client.graphql({
                query: updateCar.replaceAll("__typename", ""),
                variables: {
                  input: {
                    id: original.id,
                    userCarsId: userRecord.id,
                  },
                },
              })
            );
          });
          const modelFieldsToSave = {
            id: modelFields.id,
            username: modelFields.username ?? null,
            email: modelFields.email ?? null,
            profilePic: modelFields.profilePic ?? null,
            carFollowersId: modelFields.carFollowersId ?? null,
          };
          promises.push(
            client.graphql({
              query: updateUser.replaceAll("__typename", ""),
              variables: {
                input: {
                  id: userRecord.id,
                  ...modelFieldsToSave,
                },
              },
            })
          );
          await Promise.all(promises);
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "UserUpdateForm")}
      {...rest}
    >
      <TextField
        label="Id"
        isRequired={true}
        isReadOnly={true}
        value={id}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              id: value,
              username,
              email,
              profilePic,
              cars,
              carFollowersId,
            };
            const result = onChange(modelFields);
            value = result?.id ?? value;
          }
          if (errors.id?.hasError) {
            runValidationTasks("id", value);
          }
          setId(value);
        }}
        onBlur={() => runValidationTasks("id", id)}
        errorMessage={errors.id?.errorMessage}
        hasError={errors.id?.hasError}
        {...getOverrideProps(overrides, "id")}
      ></TextField>
      <TextField
        label="Username"
        isRequired={false}
        isReadOnly={false}
        value={username}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              id,
              username: value,
              email,
              profilePic,
              cars,
              carFollowersId,
            };
            const result = onChange(modelFields);
            value = result?.username ?? value;
          }
          if (errors.username?.hasError) {
            runValidationTasks("username", value);
          }
          setUsername(value);
        }}
        onBlur={() => runValidationTasks("username", username)}
        errorMessage={errors.username?.errorMessage}
        hasError={errors.username?.hasError}
        {...getOverrideProps(overrides, "username")}
      ></TextField>
      <TextField
        label="Email"
        isRequired={false}
        isReadOnly={false}
        value={email}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              id,
              username,
              email: value,
              profilePic,
              cars,
              carFollowersId,
            };
            const result = onChange(modelFields);
            value = result?.email ?? value;
          }
          if (errors.email?.hasError) {
            runValidationTasks("email", value);
          }
          setEmail(value);
        }}
        onBlur={() => runValidationTasks("email", email)}
        errorMessage={errors.email?.errorMessage}
        hasError={errors.email?.hasError}
        {...getOverrideProps(overrides, "email")}
      ></TextField>
      <TextField
        label="Profile pic"
        isRequired={false}
        isReadOnly={false}
        value={profilePic}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              id,
              username,
              email,
              profilePic: value,
              cars,
              carFollowersId,
            };
            const result = onChange(modelFields);
            value = result?.profilePic ?? value;
          }
          if (errors.profilePic?.hasError) {
            runValidationTasks("profilePic", value);
          }
          setProfilePic(value);
        }}
        onBlur={() => runValidationTasks("profilePic", profilePic)}
        errorMessage={errors.profilePic?.errorMessage}
        hasError={errors.profilePic?.hasError}
        {...getOverrideProps(overrides, "profilePic")}
      ></TextField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              id,
              username,
              email,
              profilePic,
              cars: values,
              carFollowersId,
            };
            const result = onChange(modelFields);
            values = result?.cars ?? values;
          }
          setCars(values);
          setCurrentCarsValue(undefined);
          setCurrentCarsDisplayValue("");
        }}
        currentFieldValue={currentCarsValue}
        label={"Cars"}
        items={cars}
        hasError={errors?.cars?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("cars", currentCarsValue)
        }
        errorMessage={errors?.cars?.errorMessage}
        getBadgeText={getDisplayValue.cars}
        setFieldValue={(model) => {
          setCurrentCarsDisplayValue(model ? getDisplayValue.cars(model) : "");
          setCurrentCarsValue(model);
        }}
        inputFieldRef={carsRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Cars"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Car"
          value={currentCarsDisplayValue}
          options={carsRecords.map((r) => ({
            id: getIDValue.cars?.(r),
            label: getDisplayValue.cars?.(r),
          }))}
          isLoading={carsLoading}
          onSelect={({ id, label }) => {
            setCurrentCarsValue(
              carsRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentCarsDisplayValue(label);
            runValidationTasks("cars", label);
          }}
          onClear={() => {
            setCurrentCarsDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchCarsRecords(value);
            if (errors.cars?.hasError) {
              runValidationTasks("cars", value);
            }
            setCurrentCarsDisplayValue(value);
            setCurrentCarsValue(undefined);
          }}
          onBlur={() => runValidationTasks("cars", currentCarsDisplayValue)}
          errorMessage={errors.cars?.errorMessage}
          hasError={errors.cars?.hasError}
          ref={carsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "cars")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              id,
              username,
              email,
              profilePic,
              cars,
              carFollowersId: value,
            };
            const result = onChange(modelFields);
            value = result?.carFollowersId ?? value;
          }
          setCarFollowersId(value);
          setCurrentCarFollowersIdValue(undefined);
        }}
        currentFieldValue={currentCarFollowersIdValue}
        label={"Car followers id"}
        items={carFollowersId ? [carFollowersId] : []}
        hasError={errors?.carFollowersId?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("carFollowersId", currentCarFollowersIdValue)
        }
        errorMessage={errors?.carFollowersId?.errorMessage}
        getBadgeText={(value) =>
          value
            ? getDisplayValue.carFollowersId(
                carFollowersIdRecords.find((r) => r.id === value) ??
                  selectedCarFollowersIdRecords.find((r) => r.id === value)
              )
            : ""
        }
        setFieldValue={(value) => {
          setCurrentCarFollowersIdDisplayValue(
            value
              ? getDisplayValue.carFollowersId(
                  carFollowersIdRecords.find((r) => r.id === value) ??
                    selectedCarFollowersIdRecords.find((r) => r.id === value)
                )
              : ""
          );
          setCurrentCarFollowersIdValue(value);
          const selectedRecord = carFollowersIdRecords.find(
            (r) => r.id === value
          );
          if (selectedRecord) {
            setSelectedCarFollowersIdRecords([selectedRecord]);
          }
        }}
        inputFieldRef={carFollowersIdRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Car followers id"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Car"
          value={currentCarFollowersIdDisplayValue}
          options={carFollowersIdRecords
            .filter(
              (r, i, arr) =>
                arr.findIndex((member) => member?.id === r?.id) === i
            )
            .map((r) => ({
              id: r?.id,
              label: getDisplayValue.carFollowersId?.(r),
            }))}
          isLoading={carFollowersIdLoading}
          onSelect={({ id, label }) => {
            setCurrentCarFollowersIdValue(id);
            setCurrentCarFollowersIdDisplayValue(label);
            runValidationTasks("carFollowersId", label);
          }}
          onClear={() => {
            setCurrentCarFollowersIdDisplayValue("");
          }}
          defaultValue={carFollowersId}
          onChange={(e) => {
            let { value } = e.target;
            fetchCarFollowersIdRecords(value);
            if (errors.carFollowersId?.hasError) {
              runValidationTasks("carFollowersId", value);
            }
            setCurrentCarFollowersIdDisplayValue(value);
            setCurrentCarFollowersIdValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("carFollowersId", currentCarFollowersIdValue)
          }
          errorMessage={errors.carFollowersId?.errorMessage}
          hasError={errors.carFollowersId?.hasError}
          ref={carFollowersIdRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "carFollowersId")}
        ></Autocomplete>
      </ArrayField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || userModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || userModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
