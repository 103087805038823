import * as React from "react";
import { Text, View } from "@aws-amplify/ui-react";
import { NavBar } from "../ui-components/index.js";




import { getCurrentUser } from 'aws-amplify/auth';

async function currentAuthenticatedUser() {
  try {
    const { username, userId, signInDetails } = await getCurrentUser();
    console.log(`The username: ${username}`);
    console.log(`The userId: ${userId}`);
    console.log(`The signInDetails: ${signInDetails}`);
  } catch (err) {
    console.log(err);
  }
}

currentAuthenticatedUser()

export default function Friends(props) {


  

  return (
    <>
        <NavBar
          className="navbar"
          width="100vw"
          displayMode="Friends"
        />
        <View
            width="100vw"
            height='55px'
        >

        </View>

        <Text
            color='white'
        >Friends</Text>
        

    </>
  );
}
