/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Autocomplete,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  ScrollView,
  Text,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { listComments, listPostContents, listUsers } from "../graphql/queries";
import { createPost, updateComment } from "../graphql/mutations";
const client = generateClient();
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function PostCreate1(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    description: "",
    postcontent: undefined,
    datetime: "",
    author: undefined,
    comments: [],
  };
  const [description, setDescription] = React.useState(
    initialValues.description
  );
  const [postcontent, setPostcontent] = React.useState(
    initialValues.postcontent
  );
  const [postcontentLoading, setPostcontentLoading] = React.useState(false);
  const [postcontentRecords, setPostcontentRecords] = React.useState([]);
  const [datetime, setDatetime] = React.useState(initialValues.datetime);
  const [author, setAuthor] = React.useState(initialValues.author);
  const [authorLoading, setAuthorLoading] = React.useState(false);
  const [authorRecords, setAuthorRecords] = React.useState([]);
  const [comments, setComments] = React.useState(initialValues.comments);
  const [commentsLoading, setCommentsLoading] = React.useState(false);
  const [commentsRecords, setCommentsRecords] = React.useState([]);
  const autocompleteLength = 10;
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setDescription(initialValues.description);
    setPostcontent(initialValues.postcontent);
    setCurrentPostcontentValue(undefined);
    setCurrentPostcontentDisplayValue("");
    setDatetime(initialValues.datetime);
    setAuthor(initialValues.author);
    setCurrentAuthorValue(undefined);
    setCurrentAuthorDisplayValue("");
    setComments(initialValues.comments);
    setCurrentCommentsValue(undefined);
    setCurrentCommentsDisplayValue("");
    setErrors({});
  };
  const [currentPostcontentDisplayValue, setCurrentPostcontentDisplayValue] =
    React.useState("");
  const [currentPostcontentValue, setCurrentPostcontentValue] =
    React.useState(undefined);
  const postcontentRef = React.createRef();
  const [currentAuthorDisplayValue, setCurrentAuthorDisplayValue] =
    React.useState("");
  const [currentAuthorValue, setCurrentAuthorValue] = React.useState(undefined);
  const authorRef = React.createRef();
  const [currentCommentsDisplayValue, setCurrentCommentsDisplayValue] =
    React.useState("");
  const [currentCommentsValue, setCurrentCommentsValue] =
    React.useState(undefined);
  const commentsRef = React.createRef();
  const getIDValue = {
    postcontent: (r) => JSON.stringify({ id: r?.id }),
    author: (r) => JSON.stringify({ id: r?.id }),
    comments: (r) => JSON.stringify({ id: r?.id }),
  };
  const postcontentIdSet = new Set(
    Array.isArray(postcontent)
      ? postcontent.map((r) => getIDValue.postcontent?.(r))
      : getIDValue.postcontent?.(postcontent)
  );
  const authorIdSet = new Set(
    Array.isArray(author)
      ? author.map((r) => getIDValue.author?.(r))
      : getIDValue.author?.(author)
  );
  const commentsIdSet = new Set(
    Array.isArray(comments)
      ? comments.map((r) => getIDValue.comments?.(r))
      : getIDValue.comments?.(comments)
  );
  const getDisplayValue = {
    postcontent: (r) => `${r?.source ? r?.source + " - " : ""}${r?.id}`,
    author: (r) => `${r?.username ? r?.username + " - " : ""}${r?.id}`,
    comments: (r) => `${r?.body ? r?.body + " - " : ""}${r?.id}`,
  };
  const validations = {
    description: [{ type: "Required" }],
    postcontent: [],
    datetime: [],
    author: [],
    comments: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const convertToLocal = (date) => {
    const df = new Intl.DateTimeFormat("default", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      calendar: "iso8601",
      numberingSystem: "latn",
      hourCycle: "h23",
    });
    const parts = df.formatToParts(date).reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
    return `${parts.year}-${parts.month}-${parts.day}T${parts.hour}:${parts.minute}`;
  };
  const fetchPostcontentRecords = async (value) => {
    setPostcontentLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ source: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listPostContents.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listPostContents?.items;
      var loaded = result.filter(
        (item) => !postcontentIdSet.has(getIDValue.postcontent?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setPostcontentRecords(newOptions.slice(0, autocompleteLength));
    setPostcontentLoading(false);
  };
  const fetchAuthorRecords = async (value) => {
    setAuthorLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ username: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listUsers.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listUsers?.items;
      var loaded = result.filter(
        (item) => !authorIdSet.has(getIDValue.author?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setAuthorRecords(newOptions.slice(0, autocompleteLength));
    setAuthorLoading(false);
  };
  const fetchCommentsRecords = async (value) => {
    setCommentsLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ body: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listComments.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listComments?.items;
      var loaded = result.filter(
        (item) => !commentsIdSet.has(getIDValue.comments?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setCommentsRecords(newOptions.slice(0, autocompleteLength));
    setCommentsLoading(false);
  };
  React.useEffect(() => {
    fetchPostcontentRecords("");
    fetchAuthorRecords("");
    fetchCommentsRecords("");
  }, []);
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          description,
          postcontent,
          datetime,
          author,
          comments,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(
                    fieldName,
                    item,
                    getDisplayValue[fieldName]
                  )
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(
                fieldName,
                modelFields[fieldName],
                getDisplayValue[fieldName]
              )
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const modelFieldsToSave = {
            description: modelFields.description,
            postPostcontentId: modelFields?.postcontent?.id,
            datetime: modelFields.datetime,
            postAuthorId: modelFields?.author?.id,
          };
          const post = (
            await client.graphql({
              query: createPost.replaceAll("__typename", ""),
              variables: {
                input: {
                  ...modelFieldsToSave,
                },
              },
            })
          )?.data?.createPost;
          const promises = [];
          promises.push(
            ...comments.reduce((promises, original) => {
              promises.push(
                client.graphql({
                  query: updateComment.replaceAll("__typename", ""),
                  variables: {
                    input: {
                      id: original.id,
                      postCommentsId: post.id,
                    },
                  },
                })
              );
              return promises;
            }, [])
          );
          await Promise.all(promises);
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "PostCreate1")}
      {...rest}
    >
      <TextField
        label="Description"
        isRequired={true}
        isReadOnly={false}
        value={description}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              description: value,
              postcontent,
              datetime,
              author,
              comments,
            };
            const result = onChange(modelFields);
            value = result?.description ?? value;
          }
          if (errors.description?.hasError) {
            runValidationTasks("description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("description", description)}
        errorMessage={errors.description?.errorMessage}
        hasError={errors.description?.hasError}
        {...getOverrideProps(overrides, "description")}
      ></TextField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              description,
              postcontent: value,
              datetime,
              author,
              comments,
            };
            const result = onChange(modelFields);
            value = result?.postcontent ?? value;
          }
          setPostcontent(value);
          setCurrentPostcontentValue(undefined);
          setCurrentPostcontentDisplayValue("");
        }}
        currentFieldValue={currentPostcontentValue}
        label={"Postcontent"}
        items={postcontent ? [postcontent] : []}
        hasError={errors?.postcontent?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("postcontent", currentPostcontentValue)
        }
        errorMessage={errors?.postcontent?.errorMessage}
        getBadgeText={getDisplayValue.postcontent}
        setFieldValue={(model) => {
          setCurrentPostcontentDisplayValue(
            model ? getDisplayValue.postcontent(model) : ""
          );
          setCurrentPostcontentValue(model);
        }}
        inputFieldRef={postcontentRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Postcontent"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search PostContent"
          value={currentPostcontentDisplayValue}
          options={postcontentRecords
            .filter((r) => !postcontentIdSet.has(getIDValue.postcontent?.(r)))
            .map((r) => ({
              id: getIDValue.postcontent?.(r),
              label: getDisplayValue.postcontent?.(r),
            }))}
          isLoading={postcontentLoading}
          onSelect={({ id, label }) => {
            setCurrentPostcontentValue(
              postcontentRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentPostcontentDisplayValue(label);
            runValidationTasks("postcontent", label);
          }}
          onClear={() => {
            setCurrentPostcontentDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchPostcontentRecords(value);
            if (errors.postcontent?.hasError) {
              runValidationTasks("postcontent", value);
            }
            setCurrentPostcontentDisplayValue(value);
            setCurrentPostcontentValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("postcontent", currentPostcontentDisplayValue)
          }
          errorMessage={errors.postcontent?.errorMessage}
          hasError={errors.postcontent?.hasError}
          ref={postcontentRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "postcontent")}
        ></Autocomplete>
      </ArrayField>
      <TextField
        label="Datetime"
        isRequired={false}
        isReadOnly={false}
        type="datetime-local"
        value={datetime && convertToLocal(new Date(datetime))}
        onChange={(e) => {
          let value =
            e.target.value === "" ? "" : new Date(e.target.value).toISOString();
          if (onChange) {
            const modelFields = {
              description,
              postcontent,
              datetime: value,
              author,
              comments,
            };
            const result = onChange(modelFields);
            value = result?.datetime ?? value;
          }
          if (errors.datetime?.hasError) {
            runValidationTasks("datetime", value);
          }
          setDatetime(value);
        }}
        onBlur={() => runValidationTasks("datetime", datetime)}
        errorMessage={errors.datetime?.errorMessage}
        hasError={errors.datetime?.hasError}
        {...getOverrideProps(overrides, "datetime")}
      ></TextField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              description,
              postcontent,
              datetime,
              author: value,
              comments,
            };
            const result = onChange(modelFields);
            value = result?.author ?? value;
          }
          setAuthor(value);
          setCurrentAuthorValue(undefined);
          setCurrentAuthorDisplayValue("");
        }}
        currentFieldValue={currentAuthorValue}
        label={"Author"}
        items={author ? [author] : []}
        hasError={errors?.author?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("author", currentAuthorValue)
        }
        errorMessage={errors?.author?.errorMessage}
        getBadgeText={getDisplayValue.author}
        setFieldValue={(model) => {
          setCurrentAuthorDisplayValue(
            model ? getDisplayValue.author(model) : ""
          );
          setCurrentAuthorValue(model);
        }}
        inputFieldRef={authorRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Author"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search User"
          value={currentAuthorDisplayValue}
          options={authorRecords
            .filter((r) => !authorIdSet.has(getIDValue.author?.(r)))
            .map((r) => ({
              id: getIDValue.author?.(r),
              label: getDisplayValue.author?.(r),
            }))}
          isLoading={authorLoading}
          onSelect={({ id, label }) => {
            setCurrentAuthorValue(
              authorRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentAuthorDisplayValue(label);
            runValidationTasks("author", label);
          }}
          onClear={() => {
            setCurrentAuthorDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchAuthorRecords(value);
            if (errors.author?.hasError) {
              runValidationTasks("author", value);
            }
            setCurrentAuthorDisplayValue(value);
            setCurrentAuthorValue(undefined);
          }}
          onBlur={() => runValidationTasks("author", currentAuthorDisplayValue)}
          errorMessage={errors.author?.errorMessage}
          hasError={errors.author?.hasError}
          ref={authorRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "author")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              description,
              postcontent,
              datetime,
              author,
              comments: values,
            };
            const result = onChange(modelFields);
            values = result?.comments ?? values;
          }
          setComments(values);
          setCurrentCommentsValue(undefined);
          setCurrentCommentsDisplayValue("");
        }}
        currentFieldValue={currentCommentsValue}
        label={"Comments"}
        items={comments}
        hasError={errors?.comments?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("comments", currentCommentsValue)
        }
        errorMessage={errors?.comments?.errorMessage}
        getBadgeText={getDisplayValue.comments}
        setFieldValue={(model) => {
          setCurrentCommentsDisplayValue(
            model ? getDisplayValue.comments(model) : ""
          );
          setCurrentCommentsValue(model);
        }}
        inputFieldRef={commentsRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Comments"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Comment"
          value={currentCommentsDisplayValue}
          options={commentsRecords.map((r) => ({
            id: getIDValue.comments?.(r),
            label: getDisplayValue.comments?.(r),
          }))}
          isLoading={commentsLoading}
          onSelect={({ id, label }) => {
            setCurrentCommentsValue(
              commentsRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentCommentsDisplayValue(label);
            runValidationTasks("comments", label);
          }}
          onClear={() => {
            setCurrentCommentsDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchCommentsRecords(value);
            if (errors.comments?.hasError) {
              runValidationTasks("comments", value);
            }
            setCurrentCommentsDisplayValue(value);
            setCurrentCommentsValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("comments", currentCommentsDisplayValue)
          }
          errorMessage={errors.comments?.errorMessage}
          hasError={errors.comments?.hasError}
          ref={commentsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "comments")}
        ></Autocomplete>
      </ArrayField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
