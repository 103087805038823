/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Autocomplete,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Icon,
  ScrollView,
  SwitchField,
  Text,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { listComments, listUsers } from "../graphql/queries";
import { createCar, updateComment, updateUser } from "../graphql/mutations";
const client = generateClient();
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function CarCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    year: "",
    make: "",
    model: "",
    posts: "",
    followers: [],
    description: "",
    owner: undefined,
    marketplace: false,
    price: "",
    location: "",
    comments: [],
  };
  const [year, setYear] = React.useState(initialValues.year);
  const [make, setMake] = React.useState(initialValues.make);
  const [model, setModel] = React.useState(initialValues.model);
  const [posts, setPosts] = React.useState(initialValues.posts);
  const [followers, setFollowers] = React.useState(initialValues.followers);
  const [followersLoading, setFollowersLoading] = React.useState(false);
  const [followersRecords, setFollowersRecords] = React.useState([]);
  const [description, setDescription] = React.useState(
    initialValues.description
  );
  const [owner, setOwner] = React.useState(initialValues.owner);
  const [ownerLoading, setOwnerLoading] = React.useState(false);
  const [ownerRecords, setOwnerRecords] = React.useState([]);
  const [marketplace, setMarketplace] = React.useState(
    initialValues.marketplace
  );
  const [price, setPrice] = React.useState(initialValues.price);
  const [location, setLocation] = React.useState(initialValues.location);
  const [comments, setComments] = React.useState(initialValues.comments);
  const [commentsLoading, setCommentsLoading] = React.useState(false);
  const [commentsRecords, setCommentsRecords] = React.useState([]);
  const autocompleteLength = 10;
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setYear(initialValues.year);
    setMake(initialValues.make);
    setModel(initialValues.model);
    setPosts(initialValues.posts);
    setFollowers(initialValues.followers);
    setCurrentFollowersValue(undefined);
    setCurrentFollowersDisplayValue("");
    setDescription(initialValues.description);
    setOwner(initialValues.owner);
    setCurrentOwnerValue(undefined);
    setCurrentOwnerDisplayValue("");
    setMarketplace(initialValues.marketplace);
    setPrice(initialValues.price);
    setLocation(initialValues.location);
    setComments(initialValues.comments);
    setCurrentCommentsValue(undefined);
    setCurrentCommentsDisplayValue("");
    setErrors({});
  };
  const [currentFollowersDisplayValue, setCurrentFollowersDisplayValue] =
    React.useState("");
  const [currentFollowersValue, setCurrentFollowersValue] =
    React.useState(undefined);
  const followersRef = React.createRef();
  const [currentOwnerDisplayValue, setCurrentOwnerDisplayValue] =
    React.useState("");
  const [currentOwnerValue, setCurrentOwnerValue] = React.useState(undefined);
  const ownerRef = React.createRef();
  const [currentCommentsDisplayValue, setCurrentCommentsDisplayValue] =
    React.useState("");
  const [currentCommentsValue, setCurrentCommentsValue] =
    React.useState(undefined);
  const commentsRef = React.createRef();
  const getIDValue = {
    followers: (r) => JSON.stringify({ id: r?.id }),
    owner: (r) => JSON.stringify({ id: r?.id }),
    comments: (r) => JSON.stringify({ id: r?.id }),
  };
  const followersIdSet = new Set(
    Array.isArray(followers)
      ? followers.map((r) => getIDValue.followers?.(r))
      : getIDValue.followers?.(followers)
  );
  const ownerIdSet = new Set(
    Array.isArray(owner)
      ? owner.map((r) => getIDValue.owner?.(r))
      : getIDValue.owner?.(owner)
  );
  const commentsIdSet = new Set(
    Array.isArray(comments)
      ? comments.map((r) => getIDValue.comments?.(r))
      : getIDValue.comments?.(comments)
  );
  const getDisplayValue = {
    followers: (r) => `${r?.username ? r?.username + " - " : ""}${r?.id}`,
    owner: (r) => `${r?.username ? r?.username + " - " : ""}${r?.id}`,
    comments: (r) => `${r?.body ? r?.body + " - " : ""}${r?.id}`,
  };
  const validations = {
    year: [{ type: "Required" }],
    make: [{ type: "Required" }],
    model: [],
    posts: [],
    followers: [],
    description: [],
    owner: [{ type: "Required", validationMessage: "User is required." }],
    marketplace: [{ type: "Required" }],
    price: [],
    location: [],
    comments: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const fetchFollowersRecords = async (value) => {
    setFollowersLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ username: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listUsers.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listUsers?.items;
      var loaded = result.filter(
        (item) => !followersIdSet.has(getIDValue.followers?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setFollowersRecords(newOptions.slice(0, autocompleteLength));
    setFollowersLoading(false);
  };
  const fetchOwnerRecords = async (value) => {
    setOwnerLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ username: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listUsers.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listUsers?.items;
      var loaded = result.filter(
        (item) => !ownerIdSet.has(getIDValue.owner?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setOwnerRecords(newOptions.slice(0, autocompleteLength));
    setOwnerLoading(false);
  };
  const fetchCommentsRecords = async (value) => {
    setCommentsLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ body: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listComments.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listComments?.items;
      var loaded = result.filter(
        (item) => !commentsIdSet.has(getIDValue.comments?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setCommentsRecords(newOptions.slice(0, autocompleteLength));
    setCommentsLoading(false);
  };
  React.useEffect(() => {
    fetchFollowersRecords("");
    fetchOwnerRecords("");
    fetchCommentsRecords("");
  }, []);
  return (
    <Grid
      as="form"
      rowGap="17px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          year,
          make,
          model,
          posts,
          followers,
          description,
          owner,
          marketplace,
          price,
          location,
          comments,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(
                    fieldName,
                    item,
                    getDisplayValue[fieldName]
                  )
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(
                fieldName,
                modelFields[fieldName],
                getDisplayValue[fieldName]
              )
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const modelFieldsToSave = {
            year: modelFields.year,
            make: modelFields.make,
            model: modelFields.model,
            posts: modelFields.posts,
            description: modelFields.description,
            userCarsId: modelFields?.owner?.id,
            marketplace: modelFields.marketplace,
            price: modelFields.price,
            location: modelFields.location,
          };
          const car = (
            await client.graphql({
              query: createCar.replaceAll("__typename", ""),
              variables: {
                input: {
                  ...modelFieldsToSave,
                },
              },
            })
          )?.data?.createCar;
          const promises = [];
          promises.push(
            ...followers.reduce((promises, original) => {
              promises.push(
                client.graphql({
                  query: updateUser.replaceAll("__typename", ""),
                  variables: {
                    input: {
                      id: original.id,
                      carFollowersId: car.id,
                    },
                  },
                })
              );
              return promises;
            }, [])
          );
          promises.push(
            ...comments.reduce((promises, original) => {
              promises.push(
                client.graphql({
                  query: updateComment.replaceAll("__typename", ""),
                  variables: {
                    input: {
                      id: original.id,
                      carCommentsId: car.id,
                    },
                  },
                })
              );
              return promises;
            }, [])
          );
          await Promise.all(promises);
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "CarCreateForm")}
      {...rest}
    >
      <Heading
        children="Add Car"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <TextField
        label="Year"
        isRequired={true}
        isReadOnly={false}
        type="number"
        step="any"
        value={year}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              year: value,
              make,
              model,
              posts,
              followers,
              description,
              owner,
              marketplace,
              price,
              location,
              comments,
            };
            const result = onChange(modelFields);
            value = result?.year ?? value;
          }
          if (errors.year?.hasError) {
            runValidationTasks("year", value);
          }
          setYear(value);
        }}
        onBlur={() => runValidationTasks("year", year)}
        errorMessage={errors.year?.errorMessage}
        hasError={errors.year?.hasError}
        {...getOverrideProps(overrides, "year")}
      ></TextField>
      <TextField
        label="Make"
        isRequired={true}
        isReadOnly={false}
        value={make}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              year,
              make: value,
              model,
              posts,
              followers,
              description,
              owner,
              marketplace,
              price,
              location,
              comments,
            };
            const result = onChange(modelFields);
            value = result?.make ?? value;
          }
          if (errors.make?.hasError) {
            runValidationTasks("make", value);
          }
          setMake(value);
        }}
        onBlur={() => runValidationTasks("make", make)}
        errorMessage={errors.make?.errorMessage}
        hasError={errors.make?.hasError}
        {...getOverrideProps(overrides, "make")}
      ></TextField>
      <TextField
        label="Model"
        isRequired={false}
        isReadOnly={false}
        value={model}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              year,
              make,
              model: value,
              posts,
              followers,
              description,
              owner,
              marketplace,
              price,
              location,
              comments,
            };
            const result = onChange(modelFields);
            value = result?.model ?? value;
          }
          if (errors.model?.hasError) {
            runValidationTasks("model", value);
          }
          setModel(value);
        }}
        onBlur={() => runValidationTasks("model", model)}
        errorMessage={errors.model?.errorMessage}
        hasError={errors.model?.hasError}
        {...getOverrideProps(overrides, "model")}
      ></TextField>
      <TextField
        label="Posts"
        isRequired={false}
        isReadOnly={false}
        value={posts}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              year,
              make,
              model,
              posts: value,
              followers,
              description,
              owner,
              marketplace,
              price,
              location,
              comments,
            };
            const result = onChange(modelFields);
            value = result?.posts ?? value;
          }
          if (errors.posts?.hasError) {
            runValidationTasks("posts", value);
          }
          setPosts(value);
        }}
        onBlur={() => runValidationTasks("posts", posts)}
        errorMessage={errors.posts?.errorMessage}
        hasError={errors.posts?.hasError}
        {...getOverrideProps(overrides, "posts")}
      ></TextField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              year,
              make,
              model,
              posts,
              followers: values,
              description,
              owner,
              marketplace,
              price,
              location,
              comments,
            };
            const result = onChange(modelFields);
            values = result?.followers ?? values;
          }
          setFollowers(values);
          setCurrentFollowersValue(undefined);
          setCurrentFollowersDisplayValue("");
        }}
        currentFieldValue={currentFollowersValue}
        label={"Followers"}
        items={followers}
        hasError={errors?.followers?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("followers", currentFollowersValue)
        }
        errorMessage={errors?.followers?.errorMessage}
        getBadgeText={getDisplayValue.followers}
        setFieldValue={(model) => {
          setCurrentFollowersDisplayValue(
            model ? getDisplayValue.followers(model) : ""
          );
          setCurrentFollowersValue(model);
        }}
        inputFieldRef={followersRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Followers"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search User"
          value={currentFollowersDisplayValue}
          options={followersRecords
            .filter((r) => !followersIdSet.has(getIDValue.followers?.(r)))
            .map((r) => ({
              id: getIDValue.followers?.(r),
              label: getDisplayValue.followers?.(r),
            }))}
          isLoading={followersLoading}
          onSelect={({ id, label }) => {
            setCurrentFollowersValue(
              followersRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentFollowersDisplayValue(label);
            runValidationTasks("followers", label);
          }}
          onClear={() => {
            setCurrentFollowersDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchFollowersRecords(value);
            if (errors.followers?.hasError) {
              runValidationTasks("followers", value);
            }
            setCurrentFollowersDisplayValue(value);
            setCurrentFollowersValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("followers", currentFollowersDisplayValue)
          }
          errorMessage={errors.followers?.errorMessage}
          hasError={errors.followers?.hasError}
          ref={followersRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "followers")}
        ></Autocomplete>
      </ArrayField>
      <TextField
        label="Description"
        isRequired={false}
        isReadOnly={false}
        value={description}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              year,
              make,
              model,
              posts,
              followers,
              description: value,
              owner,
              marketplace,
              price,
              location,
              comments,
            };
            const result = onChange(modelFields);
            value = result?.description ?? value;
          }
          if (errors.description?.hasError) {
            runValidationTasks("description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("description", description)}
        errorMessage={errors.description?.errorMessage}
        hasError={errors.description?.hasError}
        {...getOverrideProps(overrides, "description")}
      ></TextField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              year,
              make,
              model,
              posts,
              followers,
              description,
              owner: value,
              marketplace,
              price,
              location,
              comments,
            };
            const result = onChange(modelFields);
            value = result?.owner ?? value;
          }
          setOwner(value);
          setCurrentOwnerValue(undefined);
          setCurrentOwnerDisplayValue("");
        }}
        currentFieldValue={currentOwnerValue}
        label={"Owner"}
        items={owner ? [owner] : []}
        hasError={errors?.owner?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("owner", currentOwnerValue)
        }
        errorMessage={errors?.owner?.errorMessage}
        getBadgeText={getDisplayValue.owner}
        setFieldValue={(model) => {
          setCurrentOwnerDisplayValue(
            model ? getDisplayValue.owner(model) : ""
          );
          setCurrentOwnerValue(model);
        }}
        inputFieldRef={ownerRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Owner"
          isRequired={true}
          isReadOnly={false}
          placeholder="Search User"
          value={currentOwnerDisplayValue}
          options={ownerRecords
            .filter((r) => !ownerIdSet.has(getIDValue.owner?.(r)))
            .map((r) => ({
              id: getIDValue.owner?.(r),
              label: getDisplayValue.owner?.(r),
            }))}
          isLoading={ownerLoading}
          onSelect={({ id, label }) => {
            setCurrentOwnerValue(
              ownerRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentOwnerDisplayValue(label);
            runValidationTasks("owner", label);
          }}
          onClear={() => {
            setCurrentOwnerDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchOwnerRecords(value);
            if (errors.owner?.hasError) {
              runValidationTasks("owner", value);
            }
            setCurrentOwnerDisplayValue(value);
            setCurrentOwnerValue(undefined);
          }}
          onBlur={() => runValidationTasks("owner", currentOwnerDisplayValue)}
          errorMessage={errors.owner?.errorMessage}
          hasError={errors.owner?.hasError}
          ref={ownerRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "owner")}
        ></Autocomplete>
      </ArrayField>
      <SwitchField
        label="Marketplace"
        defaultChecked={false}
        isDisabled={false}
        isChecked={marketplace}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              year,
              make,
              model,
              posts,
              followers,
              description,
              owner,
              marketplace: value,
              price,
              location,
              comments,
            };
            const result = onChange(modelFields);
            value = result?.marketplace ?? value;
          }
          if (errors.marketplace?.hasError) {
            runValidationTasks("marketplace", value);
          }
          setMarketplace(value);
        }}
        onBlur={() => runValidationTasks("marketplace", marketplace)}
        errorMessage={errors.marketplace?.errorMessage}
        hasError={errors.marketplace?.hasError}
        {...getOverrideProps(overrides, "marketplace")}
      ></SwitchField>
      <TextField
        label="Price"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={price}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              year,
              make,
              model,
              posts,
              followers,
              description,
              owner,
              marketplace,
              price: value,
              location,
              comments,
            };
            const result = onChange(modelFields);
            value = result?.price ?? value;
          }
          if (errors.price?.hasError) {
            runValidationTasks("price", value);
          }
          setPrice(value);
        }}
        onBlur={() => runValidationTasks("price", price)}
        errorMessage={errors.price?.errorMessage}
        hasError={errors.price?.hasError}
        {...getOverrideProps(overrides, "price")}
      ></TextField>
      <TextField
        label="Location"
        isRequired={false}
        isReadOnly={false}
        value={location}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              year,
              make,
              model,
              posts,
              followers,
              description,
              owner,
              marketplace,
              price,
              location: value,
              comments,
            };
            const result = onChange(modelFields);
            value = result?.location ?? value;
          }
          if (errors.location?.hasError) {
            runValidationTasks("location", value);
          }
          setLocation(value);
        }}
        onBlur={() => runValidationTasks("location", location)}
        errorMessage={errors.location?.errorMessage}
        hasError={errors.location?.hasError}
        {...getOverrideProps(overrides, "location")}
      ></TextField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              year,
              make,
              model,
              posts,
              followers,
              description,
              owner,
              marketplace,
              price,
              location,
              comments: values,
            };
            const result = onChange(modelFields);
            values = result?.comments ?? values;
          }
          setComments(values);
          setCurrentCommentsValue(undefined);
          setCurrentCommentsDisplayValue("");
        }}
        currentFieldValue={currentCommentsValue}
        label={"Comments"}
        items={comments}
        hasError={errors?.comments?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("comments", currentCommentsValue)
        }
        errorMessage={errors?.comments?.errorMessage}
        getBadgeText={getDisplayValue.comments}
        setFieldValue={(model) => {
          setCurrentCommentsDisplayValue(
            model ? getDisplayValue.comments(model) : ""
          );
          setCurrentCommentsValue(model);
        }}
        inputFieldRef={commentsRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Comments"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Comment"
          value={currentCommentsDisplayValue}
          options={commentsRecords
            .filter((r) => !commentsIdSet.has(getIDValue.comments?.(r)))
            .map((r) => ({
              id: getIDValue.comments?.(r),
              label: getDisplayValue.comments?.(r),
            }))}
          isLoading={commentsLoading}
          onSelect={({ id, label }) => {
            setCurrentCommentsValue(
              commentsRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentCommentsDisplayValue(label);
            runValidationTasks("comments", label);
          }}
          onClear={() => {
            setCurrentCommentsDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchCommentsRecords(value);
            if (errors.comments?.hasError) {
              runValidationTasks("comments", value);
            }
            setCurrentCommentsDisplayValue(value);
            setCurrentCommentsValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("comments", currentCommentsDisplayValue)
          }
          errorMessage={errors.comments?.errorMessage}
          hasError={errors.comments?.hasError}
          ref={commentsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "comments")}
        ></Autocomplete>
      </ArrayField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
