/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Autocomplete,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  ScrollView,
  Text,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { listCars, listPosts, listUsers } from "../graphql/queries";
import { createComment } from "../graphql/mutations";
const client = generateClient();
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function CommentCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    body: "",
    User: undefined,
    post: undefined,
    carCommentsId: undefined,
  };
  const [body, setBody] = React.useState(initialValues.body);
  const [User, setUser] = React.useState(initialValues.User);
  const [UserLoading, setUserLoading] = React.useState(false);
  const [userRecords, setUserRecords] = React.useState([]);
  const [post, setPost] = React.useState(initialValues.post);
  const [postLoading, setPostLoading] = React.useState(false);
  const [postRecords, setPostRecords] = React.useState([]);
  const [carCommentsId, setCarCommentsId] = React.useState(
    initialValues.carCommentsId
  );
  const [carCommentsIdLoading, setCarCommentsIdLoading] = React.useState(false);
  const [carCommentsIdRecords, setCarCommentsIdRecords] = React.useState([]);
  const [selectedCarCommentsIdRecords, setSelectedCarCommentsIdRecords] =
    React.useState([]);
  const autocompleteLength = 10;
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setBody(initialValues.body);
    setUser(initialValues.User);
    setCurrentUserValue(undefined);
    setCurrentUserDisplayValue("");
    setPost(initialValues.post);
    setCurrentPostValue(undefined);
    setCurrentPostDisplayValue("");
    setCarCommentsId(initialValues.carCommentsId);
    setCurrentCarCommentsIdValue(undefined);
    setCurrentCarCommentsIdDisplayValue("");
    setErrors({});
  };
  const [currentUserDisplayValue, setCurrentUserDisplayValue] =
    React.useState("");
  const [currentUserValue, setCurrentUserValue] = React.useState(undefined);
  const UserRef = React.createRef();
  const [currentPostDisplayValue, setCurrentPostDisplayValue] =
    React.useState("");
  const [currentPostValue, setCurrentPostValue] = React.useState(undefined);
  const postRef = React.createRef();
  const [
    currentCarCommentsIdDisplayValue,
    setCurrentCarCommentsIdDisplayValue,
  ] = React.useState("");
  const [currentCarCommentsIdValue, setCurrentCarCommentsIdValue] =
    React.useState(undefined);
  const carCommentsIdRef = React.createRef();
  const getIDValue = {
    User: (r) => JSON.stringify({ id: r?.id }),
    post: (r) => JSON.stringify({ id: r?.id }),
  };
  const UserIdSet = new Set(
    Array.isArray(User)
      ? User.map((r) => getIDValue.User?.(r))
      : getIDValue.User?.(User)
  );
  const postIdSet = new Set(
    Array.isArray(post)
      ? post.map((r) => getIDValue.post?.(r))
      : getIDValue.post?.(post)
  );
  const getDisplayValue = {
    User: (r) => `${r?.username ? r?.username + " - " : ""}${r?.id}`,
    post: (r) => `${r?.description ? r?.description + " - " : ""}${r?.id}`,
    carCommentsId: (r) => `${r?.year ? r?.year + " - " : ""}${r?.id}`,
  };
  const validations = {
    body: [{ type: "Required" }],
    User: [],
    post: [],
    carCommentsId: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const fetchUserRecords = async (value) => {
    setUserLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ username: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listUsers.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listUsers?.items;
      var loaded = result.filter(
        (item) => !UserIdSet.has(getIDValue.User?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setUserRecords(newOptions.slice(0, autocompleteLength));
    setUserLoading(false);
  };
  const fetchPostRecords = async (value) => {
    setPostLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [
            { description: { contains: value } },
            { id: { contains: value } },
          ],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listPosts.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listPosts?.items;
      var loaded = result.filter(
        (item) => !postIdSet.has(getIDValue.post?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setPostRecords(newOptions.slice(0, autocompleteLength));
    setPostLoading(false);
  };
  const fetchCarCommentsIdRecords = async (value) => {
    setCarCommentsIdLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ year: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listCars.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listCars?.items;
      var loaded = result.filter((item) => carCommentsId !== item.id);
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setCarCommentsIdRecords(newOptions.slice(0, autocompleteLength));
    setCarCommentsIdLoading(false);
  };
  React.useEffect(() => {
    fetchUserRecords("");
    fetchPostRecords("");
    fetchCarCommentsIdRecords("");
  }, []);
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          body,
          User,
          post,
          carCommentsId,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(
                    fieldName,
                    item,
                    getDisplayValue[fieldName]
                  )
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(
                fieldName,
                modelFields[fieldName],
                getDisplayValue[fieldName]
              )
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const modelFieldsToSave = {
            body: modelFields.body,
            commentUserId: modelFields?.User?.id,
            postCommentsId: modelFields?.post?.id,
            carCommentsId: modelFields.carCommentsId,
          };
          await client.graphql({
            query: createComment.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFieldsToSave,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "CommentCreateForm")}
      {...rest}
    >
      <TextField
        label="Body"
        isRequired={true}
        isReadOnly={false}
        value={body}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              body: value,
              User,
              post,
              carCommentsId,
            };
            const result = onChange(modelFields);
            value = result?.body ?? value;
          }
          if (errors.body?.hasError) {
            runValidationTasks("body", value);
          }
          setBody(value);
        }}
        onBlur={() => runValidationTasks("body", body)}
        errorMessage={errors.body?.errorMessage}
        hasError={errors.body?.hasError}
        {...getOverrideProps(overrides, "body")}
      ></TextField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              body,
              User: value,
              post,
              carCommentsId,
            };
            const result = onChange(modelFields);
            value = result?.User ?? value;
          }
          setUser(value);
          setCurrentUserValue(undefined);
          setCurrentUserDisplayValue("");
        }}
        currentFieldValue={currentUserValue}
        label={"User"}
        items={User ? [User] : []}
        hasError={errors?.User?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("User", currentUserValue)
        }
        errorMessage={errors?.User?.errorMessage}
        getBadgeText={getDisplayValue.User}
        setFieldValue={(model) => {
          setCurrentUserDisplayValue(model ? getDisplayValue.User(model) : "");
          setCurrentUserValue(model);
        }}
        inputFieldRef={UserRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="User"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search User"
          value={currentUserDisplayValue}
          options={userRecords
            .filter((r) => !UserIdSet.has(getIDValue.User?.(r)))
            .map((r) => ({
              id: getIDValue.User?.(r),
              label: getDisplayValue.User?.(r),
            }))}
          isLoading={UserLoading}
          onSelect={({ id, label }) => {
            setCurrentUserValue(
              userRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentUserDisplayValue(label);
            runValidationTasks("User", label);
          }}
          onClear={() => {
            setCurrentUserDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchUserRecords(value);
            if (errors.User?.hasError) {
              runValidationTasks("User", value);
            }
            setCurrentUserDisplayValue(value);
            setCurrentUserValue(undefined);
          }}
          onBlur={() => runValidationTasks("User", currentUserDisplayValue)}
          errorMessage={errors.User?.errorMessage}
          hasError={errors.User?.hasError}
          ref={UserRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "User")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              body,
              User,
              post: value,
              carCommentsId,
            };
            const result = onChange(modelFields);
            value = result?.post ?? value;
          }
          setPost(value);
          setCurrentPostValue(undefined);
          setCurrentPostDisplayValue("");
        }}
        currentFieldValue={currentPostValue}
        label={"Post"}
        items={post ? [post] : []}
        hasError={errors?.post?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("post", currentPostValue)
        }
        errorMessage={errors?.post?.errorMessage}
        getBadgeText={getDisplayValue.post}
        setFieldValue={(model) => {
          setCurrentPostDisplayValue(model ? getDisplayValue.post(model) : "");
          setCurrentPostValue(model);
        }}
        inputFieldRef={postRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Post"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Post"
          value={currentPostDisplayValue}
          options={postRecords
            .filter((r) => !postIdSet.has(getIDValue.post?.(r)))
            .map((r) => ({
              id: getIDValue.post?.(r),
              label: getDisplayValue.post?.(r),
            }))}
          isLoading={postLoading}
          onSelect={({ id, label }) => {
            setCurrentPostValue(
              postRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentPostDisplayValue(label);
            runValidationTasks("post", label);
          }}
          onClear={() => {
            setCurrentPostDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchPostRecords(value);
            if (errors.post?.hasError) {
              runValidationTasks("post", value);
            }
            setCurrentPostDisplayValue(value);
            setCurrentPostValue(undefined);
          }}
          onBlur={() => runValidationTasks("post", currentPostDisplayValue)}
          errorMessage={errors.post?.errorMessage}
          hasError={errors.post?.hasError}
          ref={postRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "post")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              body,
              User,
              post,
              carCommentsId: value,
            };
            const result = onChange(modelFields);
            value = result?.carCommentsId ?? value;
          }
          setCarCommentsId(value);
          setCurrentCarCommentsIdValue(undefined);
        }}
        currentFieldValue={currentCarCommentsIdValue}
        label={"Car comments id"}
        items={carCommentsId ? [carCommentsId] : []}
        hasError={errors?.carCommentsId?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("carCommentsId", currentCarCommentsIdValue)
        }
        errorMessage={errors?.carCommentsId?.errorMessage}
        getBadgeText={(value) =>
          value
            ? getDisplayValue.carCommentsId(
                carCommentsIdRecords.find((r) => r.id === value) ??
                  selectedCarCommentsIdRecords.find((r) => r.id === value)
              )
            : ""
        }
        setFieldValue={(value) => {
          setCurrentCarCommentsIdDisplayValue(
            value
              ? getDisplayValue.carCommentsId(
                  carCommentsIdRecords.find((r) => r.id === value) ??
                    selectedCarCommentsIdRecords.find((r) => r.id === value)
                )
              : ""
          );
          setCurrentCarCommentsIdValue(value);
          const selectedRecord = carCommentsIdRecords.find(
            (r) => r.id === value
          );
          if (selectedRecord) {
            setSelectedCarCommentsIdRecords([selectedRecord]);
          }
        }}
        inputFieldRef={carCommentsIdRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Car comments id"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Car"
          value={currentCarCommentsIdDisplayValue}
          options={carCommentsIdRecords
            .filter(
              (r, i, arr) =>
                arr.findIndex((member) => member?.id === r?.id) === i
            )
            .map((r) => ({
              id: r?.id,
              label: getDisplayValue.carCommentsId?.(r),
            }))}
          isLoading={carCommentsIdLoading}
          onSelect={({ id, label }) => {
            setCurrentCarCommentsIdValue(id);
            setCurrentCarCommentsIdDisplayValue(label);
            runValidationTasks("carCommentsId", label);
          }}
          onClear={() => {
            setCurrentCarCommentsIdDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchCarCommentsIdRecords(value);
            if (errors.carCommentsId?.hasError) {
              runValidationTasks("carCommentsId", value);
            }
            setCurrentCarCommentsIdDisplayValue(value);
            setCurrentCarCommentsIdValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("carCommentsId", currentCarCommentsIdValue)
          }
          errorMessage={errors.carCommentsId?.errorMessage}
          hasError={errors.carCommentsId?.hasError}
          ref={carCommentsIdRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "carCommentsId")}
        ></Autocomplete>
      </ArrayField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
